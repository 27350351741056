import request from '../request'

/**
 * 获取菜单列表
 * @returns {Promise} 返回获取菜单列表的 Promise 对象
 */
export function getMenuList() {
  return request({
    url: '/user/menus',
    method: 'get'
  })
}

/**
 * 创建菜单
 * @param {Object} data 菜单数据
 * @returns {Promise} 返回创建菜单的 Promise 对象
 */
export function createMenu(data) {
  return request({
    url: '/user/menu',
    method: 'post',
    data: data
  })
}

/**
 * 编辑菜单
 * @param {number} id 菜单ID
 * @param {Object} data 更新后的菜单数据
 */
export function editMenu(id, data) {
  return request({
    url: `/user/menu/${id}`,
    method: 'put',
    data: data
  })
}

/**
 * 获取用户列表
 * @param {Object} data
 * @returns {Promise}  Promise 对象
 */
export function getUsersList(data) {
  return request({
    url: `/user/users`,
    method: 'get',
    params: data
  })
}

/**
 * 创建用户
 * @param {Object} data
 * @returns {Promise}  Promise 对象
 */
export function createUser(data) {
  return request({
    url: `/user/user`,
    method: 'post',
    data: data
  })
}
/**
 * 编辑用户
 * @param {Object} data
 * @returns {Promise}  Promise 对象
 */
export function editUser(id, data) {
  return request({
    url: `/user/user/${id}`,
    method: 'put',
    data: data
  })
}

/**
 * 重置用户密码
 * @param {Object} data
 * @returns {Promise}  Promise 对象
 */
export function restPassword(user_id) {
  return request({
    url: `/user/user/${user_id}/reset/password`,
    method: 'put'
  })
}

/**
 * 删除用户
 * @param {Object} data
 * @returns {Promise}  Promise 对象
 */
export function delUser(user_id) {
  return request({
    url: `/user/user/${user_id}`,
    method: 'delete'
  })
}

/**
 * 获取角色列表
 * @param {Object} data
 * @returns {Promise}  Promise 对象
 */
export function getRolesList(data) {
  return request({
    url: `/user/roles`,
    method: 'get',
    params: data
  })
}
/**
 * 创建角色
 * @param {Object} data
 * @returns {Promise}  Promise 对象
 */
export function createRole(data) {
  return request({
    url: `/user/role`,
    method: 'post',
    data: data
  })
}

/**
 * 编辑角色
 * @param {number} id ID
 * @param {Object} data
 * @returns {Promise}  Promise 对象
 */
export function editRole(id, data) {
  return request({
    url: `/user/role/${id}`,
    method: 'put',
    data: data
  })
}

/**
 * 获取权限列表
 * @param {Object} data
 * @returns {Promise}  Promise 对象
 */
export function getPermissionsList(data) {
  return request({
    url: `/user/permissions`,
    method: 'get',
    params: data
  })
}

/**
 * 获取上传直传参数
 * @param {Object} data
 * @returns {Promise}  Promise 对象
 */
export function uploadOssFiles(type) {
  return request({
    url: `/image/get_oss_info/${type}`,
    itemType: 'bz',
    method: 'get'
  })
}

/**
 * 集团公司列表
 * @returns {Promise} Promise 对象
 * @param {Object} data 参数对象 财务: finance 合同: contract
 */
export function getCompanyGroupApi(data) {
  return request({
    url: '/user/company/group',
    method: 'get',
    params: data
  })
}

/**
 * 操作记录
 * @returns {Promise} Promise 对象
 * @param {Object} data 参数对象
 */
export function getOperateRecordsApi(data) {
  return request({
    url: '/user/operate/records',
    method: 'get',
    params: data
  })
}

/**
 * 修改密码
 * @returns {Promise} Promise 对象
 * @param {Object} data 参数对象
 */
export function changePasswordApi(data) {
  return request({
    url: '/housekeeper/change/password',
    method: 'put',
    data,
    itemType: 'hetong'
  })
}


/**
 * 创建投资人账号
 * @returns {Promise} Promise 对象
 * @param {Object} data 参数对象
 */
export function createInvestor(data) {
  return request({
    url: '/investor',
    method: 'post',
    data,
  })
}

/**
 * 获取投资人账号
 * @returns {Promise} Promise 对象
 * @param {Object} data 参数对象
 */
export function getInvestor(params) {
  return request({
    url: '/investor',
    method: 'get',
    params,
  })
}

/**
 * 修改投资人账号
 * @returns {Promise} Promise 对象
 * @param {Object} data 参数对象
 */
export function editInvestor(data) {
  return request({
    url: '/investor/' + data.id,
    method: 'put',
    data,
  })
}

/**
 * 获取投资人资产包
 * @returns {Promise} Promise 对象
 * @param {Object} data 参数对象
 */
export function investorBag(account) {
  return request({
    url: `/investor/${account}/asset`,
    method: 'get',
  })
}

/**
 * 获取投资人房子出租信息
 * @returns {Promise} Promise 对象
 * @param {Object} data 参数对象
 */
export function assetInfo(params) {
  return request({
    url: `/investor/asset/info`,
    method: 'get',
    params
  })
}

/**
 * 操作记录
 * @returns {Promise} Promise 对象
 * @param {Object} data 参数对象
 */
export function operatingRecord(params) {
  return request({
    url: `/user/operate/records`,
    method: 'get',
    params
  })
}